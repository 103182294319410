import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grow,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { entityListSimple } from '../../interfaces/generic-entity.interface';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
    fetchCommsResults,
    fetchQueryConsultantList,
    fetchQueryDPOHList,
    fetchQueryOrgList,
    fetchRegResults,
    selectQueryConsultantList,
    selectQueryDPOHList,
} from '../../store/reducers/querySlice';
import DateRangeSelection from '../misc/DateRangeSelection';
import moment from 'moment';
import {
    ProductType,
    selectQueryCount,
    selectUserPermissions,
} from '../../store/reducers/userSlice';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { VirtualizedListBox } from '../misc/VirtualizedListBox';

interface queryFormProps {
    type: 'communications' | 'registrations';
    handleTypeChange: any;
    institutionList: entityListSimple[];
    firmList: entityListSimple[];
    sectorList: entityListSimple[];
    organizationList: entityListSimple[];
    subjectList: entityListSimple[];
}

const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: entityListSimple) => {
    return (
        <Fade in={true} key={option.code || option.title}>
            <li {...props} style={{ fontSize: 14 }}>
                {option.title}
            </li>
        </Fade>
    );
};

const QueryForm = ({
    type,
    handleTypeChange,
    institutionList,
    firmList,
    sectorList,
    organizationList,
    subjectList,
}: queryFormProps) => {
    const dispatch = useAppDispatch();

    const [warningOpen, setWarningOpen] = useState<boolean>(false);

    const [sectorError, setSectorError] = useState<boolean>(false);
    const [organizationError, setOrganizationError] = useState<boolean>(false);
    const [institutionError, setInstitutionError] = useState<boolean>(false);
    const [dpohError, setDPOHError] = useState<boolean>(false);
    const [firmError, setFirmError] = useState<boolean>(false);
    const [consultantError, setConsultantError] = useState<boolean>(false);
    const [subjectError, setSubjectError] = useState<boolean>(false);
    const [hasMadeQuery, setHasMadeQuery] = useState<boolean>(false);

    const [sectorValue, setSectorValue] = useState<entityListSimple | null>(null);
    const [organizationValue, setOrganizationValue] = useState<entityListSimple | null>(null);
    const [institutionValue, setInstitutionValue] = useState<entityListSimple | null>(null);
    const [dpohValue, setDPOHValue] = useState<entityListSimple | null>(null);
    const [firmValue, setFirmValue] = useState<entityListSimple | null>(null);
    const [consultantValue, setConsultantValue] = useState<any | null>(null);
    const [subjectValue, setSubjectValue] = useState<any | null>(null);

    const minDate = moment.utc(moment('2010-01-01')).format('YYYY-MM-DD');
    const maxDate = moment.utc(moment()).format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState<string>(
        moment(maxDate).subtract(0.7, 'years').format('YYYY-MM-DD')
    );
    const [endDate, setEndDate] = useState<string>(maxDate);

    // Mobile breakpoint is set to 1024 pixels - the "lg" breakpoint of tailwind for consistency
    const mobile_breakpoint = 1024;

    // Manage current desktop state. True if viewport width is greater than the mobile breakpoint.
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > mobile_breakpoint);

    const isInvalidDate =
        startDate > endDate ||
        moment(endDate).diff(startDate, 'days') > 1826 ||
        startDate < minDate ||
        endDate > maxDate;

    const dpoh_list: entityListSimple[] | null = useSelector(selectQueryDPOHList) || [];

    const consultant_list: entityListSimple[] | null = useSelector(selectQueryConsultantList) || [];

    const permissions = useSelector(selectUserPermissions);
    const remainingQueries = useSelector(selectQueryCount);

    // Listen for viewport resize. Update media if necessary
    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    // Report new viewport width as determined by useEffect.
    const updateMedia = () => {
        setDesktop(window.innerWidth > mobile_breakpoint);
    };

    // Skip this useEffect on first render, or when sector is not selected
    useEffect(() => {
        dispatch(fetchQueryOrgList(sectorValue ? sectorValue.code : null));
    }, [sectorValue, dispatch]);

    // Skip this useEffect on first render, or when institution is not selected
    useEffect(() => {
        if (institutionValue) dispatch(fetchQueryDPOHList(institutionValue.code));
    }, [institutionValue, dispatch]);

    // Skip this useEffect on first render, or when institution is not selected
    useEffect(() => {
        if (firmValue)
            dispatch(
                fetchQueryConsultantList({
                    firm_code: firmValue.code,
                    ind_code: sectorValue ? +sectorValue?.code : null,
                    inst_code: institutionValue ? +institutionValue.code : null,
                })
            );
    }, [firmValue, institutionValue, sectorValue, dispatch]);

    const handleSectorChange = (newValue: any, reason: string) => {
        if (sectorList.includes(newValue)) {
            setSectorError(false);
            setSectorValue(newValue);
        } else if (reason === 'clear') {
            setSectorValue(null);
            setSectorError(false);
        } else setSectorError(true);
        setHasMadeQuery(false);
    };

    const handleInstitutionChange = (newValue: any, reason: string) => {
        if (institutionList.includes(newValue)) {
            setInstitutionValue(newValue);
            setInstitutionError(false);
        } else if (reason === 'clear') {
            setInstitutionValue(null);
            setInstitutionError(false);
        } else setInstitutionError(true);
        handleDPOHChange(null, 'clear');
        setHasMadeQuery(false);
    };

    const handleFirmChange = (newValue: any, reason: string) => {
        if (firmList.includes(newValue)) {
            setFirmValue(newValue);
            setFirmError(false);
        } else if (reason === 'clear') {
            setFirmValue(null);
            setFirmError(false);
        } else setFirmError(true);
        handleConsultantChange(null, 'clear');
        setHasMadeQuery(false);
    };

    const handleOrganizationChange = (newValue: any, reason: string) => {
        if (organizationList.includes(newValue)) {
            setOrganizationValue(newValue);
            setOrganizationError(false);
        } else if (reason === 'clear') {
            setOrganizationValue(null);
            setOrganizationError(false);
        } else setOrganizationError(true);
        setHasMadeQuery(false);
    };

    const handleDPOHChange = (newValue: any, reason: string) => {
        if (dpoh_list.includes(newValue)) {
            setDPOHValue(newValue);
            setDPOHError(false);
        } else if (reason === 'clear') {
            setDPOHValue(null);
            setDPOHError(false);
        } else setDPOHError(true);
        setHasMadeQuery(false);
    };

    const handleConsultantChange = (newValue: any, reason: string) => {
        if (consultant_list.includes(newValue)) {
            setConsultantValue(newValue);
            setConsultantError(false);
        } else if (reason === 'clear') {
            setConsultantValue(null);
            setConsultantError(false);
        } else setConsultantError(true);
        setHasMadeQuery(false);
    };

    const handleSubjectChange = (newValue: any, reason: string) => {
        if (subjectList.includes(newValue)) {
            setSubjectValue(newValue);
            setSubjectError(false);
        } else if (reason === 'clear') {
            setSubjectValue(null);
            setSubjectError(false);
        } else setSubjectError(true);
        setHasMadeQuery(false);
    };

    const handleCommsSearch = () => {
        if (
            (sectorValue || institutionValue || firmValue || subjectValue || organizationValue) &&
            !isInvalidDate
        ) {
            dispatch(
                fetchCommsResults({
                    ind_code: sectorValue?.code ?? null,
                    inst_code: institutionValue?.code ?? null,
                    org: organizationValue ? organizationValue.code : null,
                    dpoh: dpohValue ? dpohValue.code : null,
                    firm: firmValue ? firmValue.code : null,
                    con: consultantValue ? consultantValue.code : null,
                    sub: subjectValue ? subjectValue.code : null,
                    startDate: startDate,
                    endDate: endDate,
                })
            );
            setHasMadeQuery(true);
            window.scrollTo(0, 700);
        } else setWarningOpen(true);
    };

    const handleRegSearch = () => {
        if ((sectorValue || firmValue || subjectValue || organizationValue) && !isInvalidDate) {
            dispatch(
                fetchRegResults({
                    ind_code: sectorValue ? +sectorValue.code : null,
                    org: organizationValue ? organizationValue.code : null,
                    dpoh: dpohValue ? dpohValue.code : null,
                    firm: firmValue ? firmValue.code : null,
                    con: consultantValue ? consultantValue.code : null,
                    sub: subjectValue ? subjectValue.code : null,
                    startDate: startDate,
                    endDate: endDate,
                })
            );
            setHasMadeQuery(true);
            window.scrollTo(0, 700);
        } else setWarningOpen(true);
    };

    return (
        <Grow in={true}>
            <div className="bg-white shadow-md rounded-md p-4">
                <Dialog open={warningOpen} keepMounted onClose={() => setWarningOpen(false)}>
                    <DialogTitle className="text-red-700 flex flex-row items-center gap-2">
                        <ErrorOutlineRoundedIcon />
                        {'Invalid Query'}
                    </DialogTitle>
                    <DialogContent>
                        <div className="flex flex-col gap-3">
                            {type === 'registrations' ? (
                                <div>Please select a Sector, Lobby Firm or Subject.</div>
                            ) : (
                                <div>
                                    Please select a Sector, Institution, Lobby Firm or Subject.
                                </div>
                            )}
                            <div>The maximum date range permitted is one year.</div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setWarningOpen(false)}>close</Button>
                    </DialogActions>
                </Dialog>

                <div className="flex flex-col justify-between">
                    <div className="text-xl font-bold">
                        {`${replace_underscores_capitalize(type)} Search`}
                    </div>
                    <div className="pt-4">
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                handleSectorChange(null, 'clear');
                                handleInstitutionChange(null, 'clear');
                                handleFirmChange(null, 'clear');
                                handleSubjectChange(null, 'clear');
                                handleOrganizationChange(null, 'clear');

                                type === 'communications'
                                    ? handleTypeChange('registrations')
                                    : handleTypeChange('communications');
                            }}
                            startIcon={<CachedRoundedIcon />}
                        >
                            {`Swap to ${
                                type === 'communications' ? 'registrations' : 'communications'
                            }`}
                        </Button>
                    </div>
                </div>
                <hr className="h-0.5 my-6 bg-slate-100 rounded" />
                <div className="mb-2 ml-2 text-sm font-light">
                    {type === 'communications'
                        ? 'Search for Lobbying Communication Reports by'
                        : `Search for ${type} in our database by`}
                </div>
                <div className="grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-6 m-2">
                        <Autocomplete
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            {...(type === 'registrations'
                                ? { disabled: true }
                                : { disabled: false })}
                            fullWidth
                            disableListWrap
                            size="small"
                            value={institutionValue}
                            onChange={(e, newValue: any, reason: string) =>
                                handleInstitutionChange(newValue, reason)
                            }
                            options={institutionList}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Institution"
                                    {...(institutionError
                                        ? {
                                              helperText: 'Select a valid Institution',
                                          }
                                        : type === 'registrations'
                                        ? {
                                              helperText: 'Selection not needed for registrations',
                                          }
                                        : {
                                              helperText: '',
                                          })}
                                    error={institutionError}
                                />
                            )}
                            renderOption={renderOptions}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-6 flex flex-row items-start my-2">
                        <ArrowForwardIcon color="info" className="m-2" />
                        <Autocomplete
                            className="mx-2"
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            {...(!institutionValue || institutionError || type === 'registrations'
                                ? { disabled: true }
                                : { disabled: false })}
                            fullWidth
                            disableListWrap
                            size="small"
                            value={dpohValue}
                            onChange={(e, newValue: any, reason) =>
                                handleDPOHChange(newValue, reason)
                            }
                            options={dpoh_list}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter by DPOH (optional)"
                                    {...(dpohError
                                        ? {
                                              helperText: 'Select a valid DPOH',
                                          }
                                        : type === 'registrations'
                                        ? {
                                              helperText: 'Selection not needed for registrations',
                                          }
                                        : {
                                              helperText: '',
                                          })}
                                    error={dpohError}
                                />
                            )}
                            renderOption={renderOptions}
                        />
                    </div>

                    <div className="col-span-12 ml-3 my-2 text-sm font-light">and/or</div>

                    <div className="col-span-12 lg:col-span-6 m-2">
                        <Autocomplete
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            fullWidth
                            disableListWrap
                            size="small"
                            value={firmValue}
                            onChange={(e, newValue: any, reason: string) =>
                                handleFirmChange(newValue, reason)
                            }
                            options={firmList}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField {...params} label="Lobby Firm" error={firmError} />
                            )}
                            renderOption={renderOptions}
                            ListboxComponent={isDesktop ? VirtualizedListBox : undefined}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-6 flex flex-row items-start my-2">
                        <ArrowForwardIcon color="info" className="m-2" />
                        <Autocomplete
                            className="mx-2"
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            {...(!firmValue ? { disabled: true } : { disabled: false })}
                            fullWidth
                            disableListWrap
                            size="small"
                            value={consultantValue}
                            onChange={(e, newValue: any, reason: string) =>
                                handleConsultantChange(newValue, reason)
                            }
                            options={consultant_list}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter by Consultant (optional)"
                                    {...(consultantError
                                        ? {
                                              helperText: 'Select a valid consultant',
                                          }
                                        : {
                                              helperText: '',
                                          })}
                                    error={consultantError}
                                />
                            )}
                            renderOption={renderOptions}
                        />
                    </div>

                    <div className="col-span-12 ml-3 my-2 text-sm font-light">and/or</div>

                    <div className="col-span-12 lg:col-span-6 m-2">
                        <Autocomplete
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            fullWidth
                            disableListWrap
                            clearOnBlur
                            size="small"
                            value={sectorValue}
                            onChange={(e, newValue: any, reason) =>
                                handleSectorChange(newValue, reason)
                            }
                            options={sectorList}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sector"
                                    value={sectorValue}
                                    {...(sectorError
                                        ? {
                                              helperText: 'Select a valid Sector',
                                          }
                                        : {
                                              helperText: '',
                                          })}
                                    error={sectorError}
                                />
                            )}
                            renderOption={renderOptions}
                        />
                    </div>

                    <div className="col-span-12 ml-3 my-2 text-sm font-light">and/or</div>

                    <div className="col-span-12 lg:col-span-6 m-2">
                        <Autocomplete
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            fullWidth
                            disableListWrap
                            size="small"
                            value={organizationValue}
                            onChange={(e, newValue: any, reason: string) =>
                                handleOrganizationChange(newValue, reason)
                            }
                            options={organizationList}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Organization"
                                    {...(organizationError
                                        ? {
                                              helperText: 'Select a valid organization',
                                          }
                                        : {
                                              helperText: '',
                                          })}
                                    error={organizationError}
                                />
                            )}
                            renderOption={renderOptions}
                            ListboxComponent={isDesktop ? VirtualizedListBox : undefined}
                        />
                    </div>

                    <div className="col-span-12 ml-3 my-2 text-sm font-light">and/or</div>

                    <div className="col-span-12 lg:col-span-6 m-2">
                        <Autocomplete
                            isOptionEqualToValue={(
                                option: entityListSimple,
                                value: entityListSimple
                            ) => option.code === value.code && option.title === value.title}
                            fullWidth
                            size="small"
                            value={subjectValue}
                            onChange={(e, newValue: any, reason: string) =>
                                handleSubjectChange(newValue, reason)
                            }
                            options={subjectList}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField {...params} label="Subject" error={subjectError} />
                            )}
                            renderOption={renderOptions}
                        />
                    </div>
                </div>
                <div className="mt-10">
                    <div className="flex flex-col">
                        <div className="text-lg mb-3">Date Range Selection</div>
                        <div className="text-sm font-light">Maximum date range of 5 years.</div>
                        <hr className="h-0.5 mt-6 mb-4 bg-slate-100 rounded" />
                        <DateRangeSelection
                            isInvalid={isInvalidDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            startDate={startDate}
                            endDate={endDate}
                            handleStartDateChange={(date: any) => {
                                setStartDate(date);
                                setHasMadeQuery(false);
                            }}
                            handleEndDateChange={(date: any) => {
                                setEndDate(date);
                                setHasMadeQuery(false);
                            }}
                        />
                    </div>
                </div>
                <div className="text-lg mt-10">{`Searches: ${
                    remainingQueries !== undefined
                        ? (permissions?.maxQuery ?? 0) - remainingQueries
                        : 0
                } of ${permissions?.maxQuery ?? 0}`}</div>
                <div className="mt-2">
                    <Button
                        variant="contained"
                        fullWidth
                        disableElevation
                        disabled={
                            permissions?.productType === ProductType.TRIAL ||
                            remainingQueries === 0 ||
                            (!subjectValue &&
                                !firmValue &&
                                !institutionValue &&
                                !sectorValue &&
                                !organizationValue) ||
                            hasMadeQuery
                        }
                        onClick={type === 'communications' ? handleCommsSearch : handleRegSearch}
                        endIcon={<NavigateNextRoundedIcon />}
                    >
                        Search {type}
                    </Button>
                </div>
            </div>
        </Grow>
    );
};

export default QueryForm;
