import React from 'react';
import { Link } from 'react-router-dom';
import { bumperSuperList, bumperListData } from '../../../interfaces/bumper-types.interface';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';
import { Button } from '@mui/material';
import Down from '@mui/icons-material/KeyboardArrowDown';
import Up from '@mui/icons-material/KeyboardArrowUp';
import CmteBumperDownload from './CmteBumperDownload';
import { EntityType } from '../../../features/config/Entities';

const Title = ({ title }: { title: string }) => {
    if (title.startsWith('URL')) {
        return (
            <div className="flex flex-row">
                <Link
                    className="text-sky-600 text-md font-medium hover:underline underline-offset-2 truncate"
                    to={title.split('#')[2]}
                    target="_blank"
                    rel="noreferrer"
                >
                    {title.split('#')[1]}
                </Link>
            </div>
        );
    } else return <div className="font-bold text-slate-600">{title}</div>;
};

const showLists = (data: bumperListData[]) => {
    return data.map((list_data: bumperListData, i: number) => {
        return (
            <div key={i} className="lg:pl-2 lg:border-l-4 lg:rounded-sm">
                <span className="lg:text-sm text-xs font-medium text-slate-700">
                    {replace_underscores_capitalize(list_data.title) + ': '}
                </span>
                {list_data.list.map((entry: any, j: number) => (
                    <span key={j} className="lg:text-sm text-xs font-medium text-slate-500">
                        {entry.link ? (
                            <Link
                                className="text-sky-700 text-sm font-medium hover:underline underline-offset-2"
                                to={entry.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {replace_underscores_capitalize(String(entry[list_data.key]))}
                            </Link>
                        ) : (
                            replace_underscores_capitalize(String(entry[list_data.key]))
                        )}
                        {j === list_data.list.length - 1 ? '' : ', '}
                    </span>
                ))}
                {list_data.list.length === 0 && (
                    <span className="lg:text-sm text-xs font-medium text-slate-400 italic">
                        {' '}
                        No data available
                    </span>
                )}
            </div>
        );
    });
};

const BumperFlowList = ({
    data,
    type,
    hiddenKeys,
    expandPrompt,
}: {
    data: bumperSuperList;
    type?: EntityType;
    hiddenKeys?: string[];
    expandPrompt?: string;
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    var main_data: bumperListData[];
    var hidden_data: bumperListData[];

    if (hiddenKeys) {
        main_data = data.lists.filter(
            (list_data: bumperListData) => !hiddenKeys.includes(list_data.title)
        );
        hidden_data = data.lists.filter((list_data: bumperListData) =>
            hiddenKeys.includes(list_data.title)
        );
    } else {
        main_data = data.lists;
        hidden_data = [];
    }

    return (
        <div className="p-2 bg-white shadow-sm rounded-md">
            <Title title={String(data.title)} />
            <div className="flex flex-col gap-1 lg:mt-2 mb-3">
                {showLists(main_data)}
                {hiddenKeys && expandPrompt && (
                    <div className="flex flex-col items-end">
                        <Button
                            size="small"
                            sx={{ fontSize: 12 }}
                            onClick={() => setOpen(!open)}
                            color={open ? 'error' : 'info'}
                            endIcon={open ? <Up /> : <Down />}
                            disabled={data.lists.length === 0}
                        >
                            {open ? 'Hide' : 'Show'} {expandPrompt}
                        </Button>
                    </div>
                )}
                {hidden_data && expandPrompt && open && <>{showLists(hidden_data)}</>}
            </div>
            {type &&
                data.committee &&
                data.meetingNo &&
                data.parliament &&
                data.session &&
                data.date && <CmteBumperDownload data={data} type={type} />}
        </div>
    );
};

export default BumperFlowList;
