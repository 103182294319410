import React from 'react';
import { LinkFormatter } from '../../../components/shared/routing/LinkFormatter';
import {
    DPOHLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
} from '../../../helpers/internal_routing';
import Modal from '../../../components/misc/Modal';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

const overallActivity = (data: recentUpdates) => {
    const percentChange: number =
        data.monthReportsLastYear > 0
            ? ((data.monthReports - data.monthReportsLastYear) / data.monthReportsLastYear) * 100
            : 0;
    var percentChangeStr: string;
    if (percentChange !== 0) {
        percentChangeStr =
            Math.abs(percentChange).toFixed(0) + '%' + (percentChange > 0 ? ' higher' : ' lower');
    } else if (percentChange === 0 && data.monthReportsLastYear > 0) {
        percentChangeStr = 'similar';
    } else {
        percentChangeStr = '100% more';
    }
    var busier;
    if (data.monthReports > data.twelveMonthAverage + 0.5 * data.twelveMonthStdDev) {
        busier = 'busier than';
    } else if (data.monthReports < data.twelveMonthAverage - 0.5 * data.twelveMonthStdDev) {
        busier = 'quieter than';
    } else {
        busier = 'on par with';
    }
    return (
        <li>
            <span className="font-medium">Overall lobbying activity</span> in the sector was{' '}
            {percentChangeStr} in {data.month}{' '}
            {percentChange === 0 && data.monthReportsLastYear > 0 ? 'as' : 'than'} in the same month
            last year with {data.monthReports} reports. {data.month} was {busier} the 12M average of{' '}
            {Math.trunc(data.twelveMonthAverage)} reports per month.
        </li>
    );
};

const RecentRegistrants = (arr: any[], month: string) => {
    const maxLength: number = 10;
    const slicedList: any[] = arr.length < maxLength ? arr : arr.slice(0, maxLength);

    const [open, setOpen] = React.useState<boolean>(false);

    if (arr.length > 0) {
        return (
            <li>
                {slicedList.map((entry: any, idx: number) => {
                    return (
                        <>
                            {entry.code && entry.parent ? (
                                <LinkFormatter
                                    urlFormatStr={organizationLinkFormatter(
                                        String(entry.code),
                                        entry.parent,
                                        entry.client
                                    )}
                                />
                            ) : (
                                entry.client
                            )}
                            {idx === arr.length - 1 ? '' : ', '}
                            {slicedList.length < arr.length && idx === slicedList.length - 1 ? (
                                <>
                                    <button
                                        className=" text-sky-500 px-1 bg-white border rounded-md hover:underline underline-offset-1"
                                        type="button"
                                        onClick={() => setOpen(true)}
                                    >
                                        and {arr.length - slicedList.length} more{' '}
                                    </button>
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    );
                })}
                <span className=""> filed new lobby registrations in {month}</span>
                <span className="">
                    {'. '}
                    <Modal title="New Registrations" open={open} onClose={() => setOpen(false)}>
                        <div className="flex flex-col gap-2 overflow-y-auto max-h-[75vh]">
                            {arr.map((entry: any, idx: number) => (
                                <div>
                                    {entry.code && entry.parent ? (
                                        <LinkFormatter
                                            urlFormatStr={organizationLinkFormatter(
                                                String(entry.code),
                                                entry.parent,
                                                entry.client
                                            )}
                                        />
                                    ) : (
                                        entry.client
                                    )}
                                </div>
                            ))}
                        </div>
                    </Modal>
                </span>
            </li>
        );
    } else {
        return <li>No new registrations filed in {month}</li>;
    }
};

const mostActiveOrg = (data: recentUpdates) => {
    const activeOrgInfo = data.mostActiveOrg;
    if (
        activeOrgInfo.activeCode &&
        activeOrgInfo.activeName &&
        activeOrgInfo.increaseName &&
        (activeOrgInfo.increaseCount > 0 || activeOrgInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.activeCode),
                        activeOrgInfo.activeParentName,
                        activeOrgInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most active organization</span> lobbying on
                the issue, filing {activeOrgInfo.activeCount} reports in {data.month}.{' '}
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.increaseCode),
                        activeOrgInfo.increaseParentName,
                        activeOrgInfo.increaseName
                    )}
                />{' '}
                increased its activity the most, filing {activeOrgInfo.increaseCount} reports,
                compared to its 12M average of {activeOrgInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveFirm = (data: recentUpdates) => {
    const activeFirmInfo = data.mostActiveFirm;
    if (activeFirmInfo.activeName) {
        return (
            <li>
                <LinkFormatter urlFormatStr={lobbyFirmLinkFormatter(activeFirmInfo.activeName)} />{' '}
                was the <span className="font-medium">most active lobby firm</span> representing
                clients lobbying on the subject, filing {activeFirmInfo.activeCount} reports in{' '}
                {data.month}.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveInstitution = (data: recentUpdates) => {
    const activeInstitutionInfo = data.mostActiveInstitution!;
    if (
        activeInstitutionInfo.activeCode &&
        activeInstitutionInfo.activeName &&
        activeInstitutionInfo.increaseCode &&
        activeInstitutionInfo.increaseName &&
        (activeInstitutionInfo.increaseCount > 0 || activeInstitutionInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.activeCode,
                        activeInstitutionInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most lobbied institution</span> on the subject
                ({activeInstitutionInfo.activeCount} reports in {data.month}).{' '}
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.increaseCode,
                        activeInstitutionInfo.increaseName
                    )}
                />{' '}
                was lobbied more heavily than usual on the subject, with{' '}
                {activeInstitutionInfo.increaseCount} reports compared to a 12M average of{' '}
                {activeInstitutionInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveDpoh = (data: recentUpdates) => {
    const activeDpohInfo = data.mostActiveDpoh;
    if (
        activeDpohInfo.activeCode &&
        activeDpohInfo.activeParentName &&
        activeDpohInfo.activeName &&
        activeDpohInfo.increaseCode &&
        activeDpohInfo.increaseParentName &&
        activeDpohInfo.increaseName &&
        (activeDpohInfo.increaseCount > 0 || activeDpohInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.activeCode,
                        activeDpohInfo.activeParentName,
                        activeDpohInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">top DPOH target</span> for organizations
                lobbying on the subject in {data.month} ({activeDpohInfo.activeCount} reports).{' '}
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.increaseCode,
                        activeDpohInfo.increaseParentName,
                        activeDpohInfo.increaseName
                    )}
                />{' '}
                was lobbied more heavily than usual, with {activeDpohInfo.increaseCount} reports
                compared to a 12M average of {activeDpohInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const SubjectWhatsNew = ({ data }: { data: recentUpdates }) => {
    return (
        <div className="lg:h-auto h-72 overflow-auto flex flex-col gap-3 text-slate-700 bg-white p-4 rounded-md shadow-md">
            {overallActivity(data)}
            {RecentRegistrants(data.newRegistrants, data.month)}
            {mostActiveOrg(data)}
            {mostActiveFirm(data)}
            {data.mostActiveInstitution ? mostActiveInstitution(data) : <></>}
            {mostActiveDpoh(data)}
        </div>
    );
};

export default SubjectWhatsNew;
