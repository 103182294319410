import React from 'react';
import { LinkFormatter } from '../shared/routing/LinkFormatter';
import {
    DPOHLinkFormatter,
    institutionLinkFormatter,
    organizationLinkFormatter,
} from '../../helpers/internal_routing';
import { recentUpdates } from '../../features/shared/interfaces/whats-new.interface';

const overallActivity = (data: recentUpdates) => {
    const percentChange: number =
        data.monthReportsLastMonth && data.monthReportsLastMonth > 0
            ? ((data.monthReports - data.monthReportsLastMonth) / data.monthReportsLastMonth) * 100
            : 0;
    let percentChangeStr: string;
    if (percentChange !== 0) {
        percentChangeStr =
            Math.abs(percentChange).toFixed(0) + '%' + (percentChange > 0 ? ' higher' : ' lower');
    } else if (
        percentChange === 0 &&
        data.monthReportsLastMonth &&
        data.monthReportsLastMonth > 0
    ) {
        percentChangeStr = 'similar';
    } else {
        percentChangeStr = '100% more';
    }
    return (
        <li>
            <span className="font-medium">Overall lobbying activity</span> on the issue (
            {data.monthReports} reports) was {percentChangeStr} in {data.month}{' '}
            {percentChange === 0 && data.monthReportsLastMonth && data.monthReportsLastMonth > 0
                ? 'as'
                : 'than'}{' '}
            in the previous month {data.monthReportsLastMonth && `(${data.monthReportsLastMonth})`}.
        </li>
    );
};

const recentRegistrants = (str_arr: any[], month: string) => {
    const endPhrase =
        str_arr.length > 1
            ? 'filed new lobby registrations that included keywords relating to the issue'
            : 'filed a new lobby registration that included keywords relating to the issue';
    if (str_arr.length > 0) {
        return (
            <li>
                {str_arr.map((entry: any, idx: number) => {
                    return (
                        <>
                            {entry.code && entry.parent ? (
                                <LinkFormatter
                                    urlFormatStr={organizationLinkFormatter(
                                        String(entry.code),
                                        entry.parent,
                                        entry.client
                                    )}
                                />
                            ) : (
                                entry.client
                            )}
                            {idx === str_arr.length - 1 ? '' : ', '}
                        </>
                    );
                })}
                <span className="">
                    {' '}
                    {endPhrase} in {month}.
                </span>
            </li>
        );
    } else {
        return <li>No new registrations were filed in {month}.</li>;
    }
};

const mostActiveOrg = (data: recentUpdates) => {
    const activeOrgInfo = data.mostActiveOrg;
    if (activeOrgInfo.activeCode && activeOrgInfo.activeName) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.activeCode),
                        activeOrgInfo.activeParentName,
                        activeOrgInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most active organization</span> lobbying on
                the issue, filing {activeOrgInfo.activeCount} reports in {data.month}.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveInstitution = (data: recentUpdates) => {
    const activeInstitutionInfo = data.mostActiveInstitution!;
    if (activeInstitutionInfo.activeCode && activeInstitutionInfo.activeName) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.activeCode,
                        activeInstitutionInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most lobbied institution</span> on the issue (
                {activeInstitutionInfo.activeCount} reports in {data.month}).
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveDpoh = (data: recentUpdates) => {
    const activeDpohInfo = data.mostActiveDpoh;
    if (activeDpohInfo.activeCode && activeDpohInfo.activeParentName && activeDpohInfo.activeName) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.activeCode,
                        activeDpohInfo.activeParentName,
                        activeDpohInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">top DPOH target</span> for organizations
                lobbying on the issue in {data.month} ({activeDpohInfo.activeCount} reports).
            </li>
        );
    } else {
        return <></>;
    }
};

const monthlyKeywords = (data: recentUpdates) => {
    const keywords = data.keywordMentions!;
    if (keywords > 0) {
        return (
            <li>
                Issue keywords were mentioned {keywords} time(s) in Hansard and Committee meetings
                in {data.month}
            </li>
        );
    } else {
        return <></>;
    }
};

const monthlyMpList = (data: recentUpdates) => {
    const mps = data.mpList!;
    if (mps.length > 0) {
        return (
            <li>
                <span className="font-medium">MPs who mentioned</span> issue keywords in Hansard and
                Committee meetings in {data.month}:{' '}
                {mps.map((entry: any, idx: number) => {
                    return (
                        <span key={idx}>
                            {entry.name} ({entry.count}){idx === mps.length - 1 ? '' : ', '}
                        </span>
                    );
                })}
            </li>
        );
    } else {
        return <></>;
    }
};

const WhatsNew = ({ data, name }: { data: recentUpdates; name: string }) => {
    return (
        <div className="lg:h-auto h-72 overflow-auto flex flex-col gap-3 text-slate-700 bg-white p-4 rounded-md shadow-md">
            {data.keywordMentions ? monthlyKeywords(data) : <></>}
            {data.mpList ? monthlyMpList(data) : <></>}
            {overallActivity(data)}
            {recentRegistrants(data.newRegistrants, data.month)}
            {mostActiveOrg(data)}
            {data.mostActiveInstitution ? mostActiveInstitution(data) : <></>}
            {mostActiveDpoh(data)}
        </div>
    );
};

export default WhatsNew;
