import React from 'react';
import { Fade } from '@mui/material';
import LoadingTable from '../../../../components/generic-dashboards/loading/LoadingTable';
import { MissingData } from '../../../../components/misc/MissingData';
import LoadingUpdates from '../../../../components/generic-dashboards/loading/LoadingUpdates';
import LiqExhibitTitleContainer from '../layout/LiqExhibitTitleContainer';
import LiqCard from '../layout/LiqCard';
import { ExternalLinkFormatter } from '../../../../components/shared/routing/LinkFormatter';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import LiqAccentButton from '../inputs/LiqAccentButton';

// Title and subtitle are optional components with defaults specified in function signature below.
interface listProps {
    title?: any;
    subtitle?: string;
    data: any[] | null;
    titleKey?: string;
    dataKey: string;
    linkKey: string;
    loading: boolean;
    defaultNumRows?: number;
    titleProvider?: (data: any) => React.ReactNode;
}

const ListWithLinks = ({
    title = '',
    subtitle = '',
    data,
    titleKey,
    dataKey,
    linkKey,
    loading,
    defaultNumRows = 5,
    titleProvider,
}: listProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Fade in={true}>
            <div>
                <LiqExhibitTitleContainer title={title} subtitle={subtitle} />
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                {loading ? (
                    <LoadingTable />
                ) : !loading && !data ? (
                    <MissingData />
                ) : data ? (
                    <LiqCard>
                        {loading ? (
                            <LoadingUpdates />
                        ) : !loading && !data ? (
                            <MissingData />
                        ) : data ? (
                            <ul className="list-inside list-disc flex flex-col gap-3">
                                {data
                                    .slice(0, open ? data.length : defaultNumRows)
                                    .map((entry: any, idx: number) =>
                                        entry[linkKey] ? (
                                            <li key={idx}>
                                                {titleProvider && titleProvider(entry)}{' '}
                                                <ExternalLinkFormatter
                                                    urlLink={entry[linkKey]}
                                                    urlTitle={entry[dataKey]}
                                                />
                                            </li>
                                        ) : (
                                            <li key={idx}>{entry[dataKey]}</li>
                                        )
                                    )}
                            </ul>
                        ) : (
                            <></>
                        )}
                        <div className="flex flex-row justify-end">
                            <LiqAccentButton onClick={() => setOpen(!open)}>
                                {open
                                    ? `hide ${data.length - defaultNumRows} rows`
                                    : `show ${data.length - defaultNumRows} more rows`}
                                {open ? (
                                    <RemoveRoundedIcon fontSize="small" />
                                ) : (
                                    <AddRoundedIcon fontSize="small" />
                                )}
                            </LiqAccentButton>
                        </div>
                    </LiqCard>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default ListWithLinks;
