import React from 'react';
import { Link } from 'react-router-dom';

const LinkFormatter = ({ urlFormatStr }: { urlFormatStr: string }) => {
    return (
        <Link
            className="text-sky-600 hover:underline underline-offset-2"
            to={urlFormatStr.split('#')[2]}
            rel="noreferrer"
        >
            {urlFormatStr.split('#')[1]}
        </Link>
    );
};

const ExternalLinkFormatter = ({ urlLink, urlTitle }: { urlLink: string; urlTitle: string }) => {
    return (
        <Link
            className="text-sky-600 hover:underline underline-offset-2"
            to={urlLink}
            rel="noreferrer"
        >
            {urlTitle}
        </Link>
    );
};

export { LinkFormatter, ExternalLinkFormatter };
