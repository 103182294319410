import React from 'react';
import { LinkFormatter } from '../shared/routing/LinkFormatter';
import {
    DPOHLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
} from '../../helpers/internal_routing';
import { recentUpdates } from '../../features/shared/interfaces/whats-new.interface';

const overallActivity = (data: recentUpdates, name: string) => {
    const percentChange: number =
        data.monthReportsLastYear > 0
            ? ((data.monthReports - data.monthReportsLastYear) / data.monthReportsLastYear) * 100
            : 0;
    var percentChangeStr: string;
    if (percentChange !== 0) {
        percentChangeStr =
            Math.abs(percentChange).toFixed(0) + '%' + (percentChange > 0 ? ' higher' : ' lower');
    } else if (percentChange === 0 && data.monthReportsLastYear > 0) {
        percentChangeStr = 'similar';
    } else {
        percentChangeStr = '100% more';
    }
    var busier;
    if (data.monthReports > data.twelveMonthAverage + 0.5 * data.twelveMonthStdDev) {
        busier = 'busier than';
    } else if (data.monthReports < data.twelveMonthAverage - 0.5 * data.twelveMonthStdDev) {
        busier = 'quieter than';
    } else {
        busier = 'on par with';
    }
    return (
        <li>
            <span className="font-medium">Overall lobbying activity</span> in the sector was{' '}
            {percentChangeStr} in {data.month}{' '}
            {percentChange === 0 && data.monthReportsLastYear > 0 ? 'as' : 'than'} in the same month
            last year with {data.monthReports} reports. {data.month} was {busier} the 12M average of{' '}
            {Math.trunc(data.twelveMonthAverage)} reports per month.
        </li>
    );
};

const RecentRegistrants = (str_arr: any[], sectorName: string, month: string) => {
    const endPhrase =
        str_arr.length > 1 ? 'filed new lobby registrations' : 'filed a new lobby registration';
    if (str_arr.length > 0) {
        return (
            <li>
                {str_arr.map((entry: any, idx: number) => {
                    return (
                        <>
                            <LinkFormatter
                                urlFormatStr={organizationLinkFormatter(
                                    String(entry.code),
                                    sectorName,
                                    entry.client
                                )}
                            />
                            {idx === str_arr.length - 1 ? '' : ', '}
                        </>
                    );
                })}
                <span className="">
                    {' '}
                    {endPhrase} in {month}.
                </span>
            </li>
        );
    } else {
        return <li>No new registrations were filed in {month}.</li>;
    }
};

const mostActiveOrg = (data: recentUpdates, sectorName: string) => {
    const activeOrgInfo = data.mostActiveOrg;
    if (
        activeOrgInfo.activeCode &&
        activeOrgInfo.activeName &&
        activeOrgInfo.increaseName &&
        (activeOrgInfo.increaseCount > 0 || activeOrgInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.activeCode),
                        sectorName,
                        activeOrgInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most active organization</span> in the sector,
                filing {activeOrgInfo.activeCount} reports in {data.month}.{' '}
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.increaseCode),
                        sectorName,
                        activeOrgInfo.increaseName
                    )}
                />{' '}
                increased its activity the most, filing {activeOrgInfo.increaseCount} reports,
                compared to its 12M average of {Math.trunc(activeOrgInfo.increaseAvg)} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveFirm = (data: recentUpdates) => {
    const activeFirmInfo = data.mostActiveFirm;
    if (activeFirmInfo.activeName) {
        return (
            <li>
                <LinkFormatter urlFormatStr={lobbyFirmLinkFormatter(activeFirmInfo.activeName)} />{' '}
                was the <span className="font-medium">most active lobby firm</span> representing
                clients in the sector, filing {activeFirmInfo.activeCount} reports in {data.month}.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveInstitution = (data: recentUpdates) => {
    const activeInstitutionInfo = data.mostActiveInstitution!;
    if (
        activeInstitutionInfo.activeCode &&
        activeInstitutionInfo.activeName &&
        activeInstitutionInfo.increaseCode &&
        activeInstitutionInfo.increaseName &&
        (activeInstitutionInfo.increaseCount > 0 || activeInstitutionInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.activeCode,
                        activeInstitutionInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">top institution lobbying target</span> by
                firms in the sector in {data.month} ({activeInstitutionInfo.activeCount} reports).{' '}
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.increaseCode,
                        activeInstitutionInfo.increaseName
                    )}
                />{' '}
                saw the biggest increase in lobbying by the sector, with{' '}
                {activeInstitutionInfo.increaseCount} reports compared to a 12M average of{' '}
                {Math.trunc(activeInstitutionInfo.increaseAvg)} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveDpoh = (data: recentUpdates) => {
    const activeDpohInfo = data.mostActiveDpoh;
    if (
        activeDpohInfo.activeCode &&
        activeDpohInfo.activeParentName &&
        activeDpohInfo.activeName &&
        activeDpohInfo.increaseCode &&
        activeDpohInfo.increaseParentName &&
        activeDpohInfo.increaseName &&
        (activeDpohInfo.increaseCount > 0 || activeDpohInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.activeCode,
                        activeDpohInfo.activeParentName,
                        activeDpohInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">top DPOH lobbying target</span> by firms in
                the sector in {data.month} ({activeDpohInfo.activeCount} reports).{' '}
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.increaseCode,
                        activeDpohInfo.increaseParentName,
                        activeDpohInfo.increaseName
                    )}
                />{' '}
                saw the biggest increase in lobbying by the sector, with{' '}
                {activeDpohInfo.increaseCount} reports compared to a 12M average of{' '}
                {Math.trunc(activeDpohInfo.increaseAvg)} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const monthlyKeywords = (data: recentUpdates) => {
    const keywords = data.monthlyKeywords!;
    if (keywords.length > 0) {
        return (
            <li>
                <span className="font-medium">Monthly registration keywords: </span>
                {keywords.map((word: string, idx: number) => {
                    return (
                        <span key={idx}>
                            {word}
                            {idx === keywords.length - 1 ? '' : ', '}
                        </span>
                    );
                })}
            </li>
        );
    } else {
        return <></>;
    }
};

const WhatsNew = ({ data, name }: { data: recentUpdates; name: string }) => {
    return (
        <div className="lg:h-auto h-72 overflow-auto flex flex-col gap-3 text-slate-700 bg-white p-4 rounded-md shadow-md">
            {overallActivity(data, name)}
            {RecentRegistrants(data.newRegistrants, name, data.month)}
            {mostActiveOrg(data, name)}
            {mostActiveFirm(data)}
            {data.mostActiveInstitution ? mostActiveInstitution(data) : <></>}
            {mostActiveDpoh(data)}
            {data.monthlyKeywords ? monthlyKeywords(data) : <></>}
        </div>
    );
};

export default WhatsNew;
