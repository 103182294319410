import React from 'react';

interface LiqAccentButtonProps {
    onClick: () => void;
    children: React.ReactNode;
}

const LiqAccentButton: React.FC<LiqAccentButtonProps> = ({ onClick, children }) => {
    return (
        <button
            className="text-sm flex flex-row gap-1 py-1 px-2 text-slate-600 hover:bg-slate-50 rounded-md"
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default LiqAccentButton;
