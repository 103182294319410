import React from 'react';
import liq_badge from '../assets/vectors/LIQ_badge.svg';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

/*
    Component: Landing.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Main landing page of the app. 
*/
const Landing = () => {
    return (
        <div className="md:pt-10 pt-32">
            <div id="top" className="grid grid-cols-12">
                <div className="col-start-2 col-end-12 flex flex-col py-10">
                    <div className="flex flex-row items-center justify-between md:h-[80vh]">
                        <div className="max-w-4xl">
                            <h1 className="text-3xl lg:text-7xl mb-8">
                                Lobby<b className="font-black">IQ</b>
                            </h1>
                            <img
                                src={liq_badge}
                                className="h-24 absolute top-[12vh] right-12 lg:hidden"
                                alt="logo"
                            />
                            <div className="flex flex-row justify-between items-center">
                                <div className="text-2xl lg:text-4xl">
                                    Faster, Easier, Smarter <br />
                                    <span className="text-sky-600">Intelligence</span> on
                                    Parliamentary Affairs
                                </div>
                            </div>
                            <div className="mt-8 lg:mr-12 text-md lg:text-xl">
                                LobbyIQ helps organizations effortlessly monitor and interpret the
                                complex landscape of federal parliamentary affairs through the power
                                of AI-driven analytics.
                            </div>
                            <div className="mt-8 flex flex-row gap-3">
                                <Button component={Link} to="/contact" variant="contained">
                                    Request a Call
                                </Button>
                            </div>
                        </div>
                        <img src={liq_badge} className={'w-72 lg:flex hidden'} alt="logo" />
                    </div>
                </div>
                <div className="col-start-1 col-end-13 flex flex-col gap-10 bg-gradient-to-b from-sky-950 to-sky-400">
                    <div style={{ paddingBottom: '100px' }}>
                        <div className="text-center text-white text-xl lg:text-3xl lg:mt-20 lg:mb-10 mt-10 mb-5 font-semibold">
                            Solutions at a Glance
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 px-6 lg:px-20">
                            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col text-left ">
                                <div className="text-sky-900 lg:text-2xl text-md font-semibold mb-4 text-center">
                                    Issue Tracker
                                </div>
                                <p className="mb-6 text-sm lg:text-xl flex-grow">
                                    Automatically track mentions of issue keywords in Hansard, House
                                    Committees, Senate Committees, Committee Briefs, News Releases,
                                    and Lobby Registrations.
                                </p>
                                <img
                                    src={process.env.PUBLIC_URL + '/Landing_Issue_Icon.png'}
                                    alt="Issue Tracker Icon"
                                    className="h-auto w-full max-w-[192px] object-contain mb-6 self-center"
                                />
                                <Link
                                    to="/features/tracking"
                                    className="font-semibold text-sm lg:text-xl bg-sky-900 text-white px-4 py-3 rounded-md hover:bg-white border-solid border-2 border-sky-900 hover:text-sky-900 transition-all w-full text-center"
                                >
                                    Learn More
                                </Link>
                            </div>

                            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col text-left ">
                                <div className="text-sky-700 lg:text-2xl text-md font-semibold mb-4 text-center">
                                    Committee Monitor
                                </div>
                                <p className="mb-6 text-sm lg:text-xl flex-grow">
                                    Receive summaries and transcripts of House and Senate meetings
                                    within an hour of the meeting ending.
                                </p>
                                <img
                                    src={process.env.PUBLIC_URL + '/Landing_Committee_Icon.png'}
                                    alt="Committee Monitor Icon"
                                    className="h-auto w-full max-w-[192px] object-contain mb-6 self-center"
                                />
                                <Link
                                    to="/features/monitor"
                                    className="font-semibold text-sm lg:text-xl bg-sky-700 text-white px-4 py-3 rounded-md hover:bg-white border-solid border-2 border-sky-700 hover:text-sky-700 transition-all w-full text-center"
                                >
                                    Learn More
                                </Link>
                            </div>

                            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col text-left ">
                                <div className="text-sky-500 lg:text-2xl text-md font-semibold mb-4 text-center">
                                    Profile Dashboards
                                </div>
                                <p className="mb-6 text-sm lg:text-xl flex-grow">
                                    Track lobbying activities across sectors, organizations, and
                                    government entities with real-time analytics and actionable
                                    insights.
                                </p>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/Landing_Profile_Dashboards_Icon.png'
                                    }
                                    alt="Profile Dashboards Icon"
                                    className="h-auto w-full max-w-[192px] object-contain mb-6 self-center"
                                />
                                <Link
                                    to="/features/dashboards"
                                    className="font-semibold text-sm lg:text-xl bg-sky-500 text-white px-4 py-3 rounded-md hover:bg-white border-solid border-2 border-sky-500 hover:text-sky-500 transition-all w-full text-center"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:col-start-2 lg:col-end-12 col-start-2 col-end-12 flex flex-col gap-10">
                    <div className="text-black text-2xl lg:text-4xl mt-10"></div>

                    <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-12 md:gap-8 gap-8">
                        <div className="bg-white rounded-lg shadow-md flex flex-col items-center">
                            <Link
                                to="/features/updates"
                                className="w-full block bg-liq-lime text-white lg:text-2xl text-md font-semibold py-3 rounded-t-md hover:bg-white hover:text-liq-lime border-2 border-liq-lime transition-all group text-center"
                            >
                                Stay Ahead Updates
                                <ArrowForwardIcon className="ml-2 transition-colors group-hover:text-liq-lime" />
                            </Link>
                            <div className="flex-1 flex items-center justify-center p-8">
                                <img
                                    src={process.env.PUBLIC_URL + '/Landing_Stay_Ahead_Icon.png'}
                                    alt="Stay Ahead Updates"
                                    className="block h-full w-full rounded-lg object-contain max-w-[220px] max-h-[220px]"
                                />
                            </div>
                            <div className="px-8 pb-8 text-sm lg:text-xl">
                                Receive weekly lobbying updates and keyword alerts, plus monthly
                                summaries of key issues and stakeholders.
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-md flex flex-col items-center">
                            <Link
                                to="/features/research"
                                className="w-full block bg-liq-teal text-white lg:text-2xl font-semibold py-3 rounded-t-md hover:bg-white hover:text-liq-teal border-2 border-liq-teal transition-all group text-center"
                            >
                                Research Hub
                                <ArrowForwardIcon className="ml-2 transition-colors group-hover:text-liq-teal" />
                            </Link>
                            <div className="flex-1 flex items-center justify-center p-8">
                                <img
                                    src={process.env.PUBLIC_URL + '/Landing_Research_Hub_Icon.png'}
                                    alt="Research Hub"
                                    className="block h-full w-full rounded-lg object-contain max-w-[220px] max-h-[220px]"
                                />
                            </div>
                            <div className="px-8 pb-8 text-sm lg:text-xl">
                                Analyze cleaned lobbying data, compare 15 years of GR activity, and
                                generate presentation-ready charts and tables.
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-md flex flex-col items-center">
                            <Link
                                to="/features/gr-insider"
                                className="w-full block bg-liq-lavender text-white lg:text-2xl font-semibold py-3 rounded-t-md hover:bg-white hover:text-liq-lavender border-2 border-liq-lavender transition-all group text-center"
                            >
                                GR Insider
                                <ArrowForwardIcon className="ml-2 transition-colors group-hover:text-liq-lavender" />
                            </Link>
                            <div className="flex-1 flex items-center justify-center p-8">
                                <img
                                    src={process.env.PUBLIC_URL + '/Landing_GR_Insider_Icon.png'}
                                    alt="GR Insider"
                                    className="block h-full w-full rounded-lg object-contain max-w-[220px] max-h-[220px]"
                                />
                            </div>
                            <div className="px-8 pb-8 text-sm lg:text-xl">
                                Assess GR firm market positions, track consultant client
                                engagements, and identify business development opportunities.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-20 text-center">
                <Button
                    variant="contained"
                    sx={{
                        padding: '10px 80px',
                        fontSize: '18px',
                        borderRadius: '8px',
                    }}
                >
                    REQUEST A CALL
                </Button>
            </div>
        </div>
    );
};

export default Landing;
